import classNames from 'classnames';
import {useEffect, useState} from "react";
import styles from './styles/SlateEditor.module.scss';
import {BlockButton, LinkButton, MarkButton, ListButton} from "./buttons";
import {EditLinkToolbar} from "./EditLinkToolbar";
import {useEditorState, useSelectionExpanded, useEditorSelection} from "@udecode/plate/react";
import {getActiveLink} from "./plugins/links";
import {MarkType, NodeType} from "./utils";
import {getDOMSelectionBoundingClientRect} from "@udecode/plate-floating";
import {Range} from "slate";

export const toolbarHeight = 50;
const toolbarWidth = 280;

const Toolbar = (props) => {
    const editor = useEditorState();
    const [editLinkViewOpen, setEditLinkViewOpen] = useState(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [toolbarOffset, setToolbarOffset] = useState({offsetTop: 0, offsetLeft: 0});
    const [arrowLeft, setArrowLeft] = useState<number | null>(null);
    const [activeLinkSelection, setActiveLinkSelection] = useState<Range | null>(null);
    const selectionExpanded = useSelectionExpanded();
    const selection = useEditorSelection();

    function resetToolbar() {
        setToolbarOffset({offsetTop: 0, offsetLeft: 0});
        setArrowLeft(null);
    }

    const toolbarClassName = classNames({
        [props.className]: props.className,
        [styles.menu]: true,
        [styles.toolbar]: true,
        [styles.toolbarExpand]: editLinkViewOpen,
    });

    useEffect(() => {
        if (selectionExpanded) { // show toolbar if there is a selection OR currently editing
            const sr = getDOMSelectionBoundingClientRect();
            const containerRect = props.containerRef.current?.getBoundingClientRect();

            if (containerRect) {
                const offsetTop = sr.top - containerRect.top - toolbarHeight;
                let offsetLeft = sr.left - containerRect.left - (toolbarWidth / 2) + sr.width / 2;
                let _arrowLeft = (toolbarWidth / 2);

                // handle cases where the container is smaller than where the toolbar wants to go
                if (offsetLeft < containerRect.left) {
                    _arrowLeft = sr.left - containerRect.left + sr.width / 2;
                    offsetLeft = 0;
                } else if (offsetLeft + toolbarWidth > containerRect.width) {
                    _arrowLeft -= (containerRect.width - offsetLeft - toolbarWidth);
                    offsetLeft = containerRect.width - toolbarWidth;
                }

                setArrowLeft(_arrowLeft);
                setToolbarOffset({offsetLeft, offsetTop});
            }

        } else {
            resetToolbar();
        }
    }, [editor.selection]);

    useEffect(() => {
        if (selection && editLinkViewOpen) {
            setEditLinkViewOpen(false);
        }

        // we want to preserve the activeLink (for editing) and selection (for wrapping/unwrapping) so we don't
        // lose it when entering/leaving edit link view
        if (selection) {
            const {url} = getActiveLink(editor);
            setLinkUrl(url);
            setActiveLinkSelection(selection);
        }
    }, [selection]);

    return <div
        className={toolbarClassName}
        style={{top: toolbarOffset.offsetTop, left: toolbarOffset.offsetLeft}}
    >
        {editLinkViewOpen ? <EditLinkToolbar
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            activeLinkSelection={activeLinkSelection}
            setActiveLinkSelection={setActiveLinkSelection}
            setEditLinkViewOpen={setEditLinkViewOpen}
        /> : <><BlockButton format={NodeType.HEADING_THREE}/>
            <ListButton format={NodeType.UNORDERED_LIST}/>
            <ListButton format={NodeType.ORDERED_LIST}/>
            <BlockButton format={NodeType.BLOCK_QUOTE}/>
            <MarkButton format={MarkType.STRONG}/>
            <MarkButton format={MarkType.EMPHASIS}/>
            <LinkButton activeLinkSelection={activeLinkSelection} setEditLinkViewOpen={setEditLinkViewOpen}/>
        </>}
        {typeof arrowLeft === 'number' && <span className={styles.arrow} style={{left: arrowLeft}}/>}
    </div>
};

export default Toolbar;
