import classNames from "classnames";
import {
    useEditorState,
    useMarkToolbarButtonState,
    useMarkToolbarButton,
    useEditorRef,
    useSelectionExpanded,
} from "@udecode/plate/react";
import Button from "../utilities/Button";
import {
    isBlockActive,
    toggleBlock,
} from "./commands";
import styles from "./styles/SlateEditor.module.scss";
import {isLinkActive} from "./plugins/links";
import PropTypes from "prop-types";
import {MarkType, NodeType} from "./utils";
import {
    useListToolbarButton,
    useListToolbarButtonState,
} from "@udecode/plate-list/react";

export const ButtonWrapper = (props: {
    className?: string,
    pressed?: boolean,
    [restProps: string]: any,
}) => {
    const {className = "", pressed = false, ...restProps} = props;
    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonActive]: pressed,
        [className]: className,
    });

    return <Button {...restProps} data-plate-focus className={buttonClassName} />;
};

export const BlockButton = props => {
    const { format } = props;
    const editor = useEditorRef();
    const blockButtonClassNames = classNames("fa", {
        "fa-heading": format === NodeType.HEADING_THREE,
        "fa-quote-left": format === NodeType.BLOCK_QUOTE,
    });

    return (
        <ButtonWrapper
            className={blockButtonClassNames}
            pressed={isBlockActive(editor, [format])}
            onClick={() => toggleBlock(editor, format)}
            onMouseDown={event => event.preventDefault()}
        />
    );
};


export const ListButton = ({format}) => {
    const state = useListToolbarButtonState({nodeType: format});
    const {props} = useListToolbarButton(state)
    const blockButtonClassNames = classNames("fa", {
        "fa-list-ul": format === NodeType.UNORDERED_LIST,
        "fa-list-ol": format === NodeType.ORDERED_LIST,
    });

    return (
        <ButtonWrapper
            className={blockButtonClassNames}
            {...props}
        />
    );
};


export const MarkButton = props => {
    const { format } = props;
    const buttonState = useMarkToolbarButtonState({nodeType: format, clear: []});
    const buttonProps = useMarkToolbarButton(buttonState);

    const markButtonClassNames = classNames("fa", {
        "fa-bold": format === MarkType.STRONG,
        "fa-italic": format === MarkType.EMPHASIS,
    });

    return (
        <ButtonWrapper
            className={markButtonClassNames}
            {...buttonProps.props}
        />
    );
};

export const LinkButton = props => {
    const markButtonClassNames = classNames("fa", `fa-link`);
    const editor = useEditorState();
    const expanded = useSelectionExpanded();
    const selectionHasActiveLink = isLinkActive(editor);

    return (
        <ButtonWrapper
            className={markButtonClassNames}
            pressed={selectionHasActiveLink}
            disabled={selectionHasActiveLink ? false : !expanded}
            onClick={event => {
                event.preventDefault();
                props.setEditLinkViewOpen(true);
            }}
        />
    );
};

MarkButton.propTypes = {
    format: PropTypes.string.isRequired,
};

BlockButton.propTypes = {
    format: PropTypes.string.isRequired,
};
