import {isMissingProtocol} from "../../../../utils/SchoolBlocksUtilities";
import {NodeType} from "../../utils";

export const editLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }
    const { selection } = editor;
    const textSelection = editor.api.string(selection);
    wrapLink(editor, url, textSelection);
};

export const isLinkActive = editor => {
    const [link] = editor.api.nodes({ match: n => n.type === NodeType.LINK });
    return !!link;
};

export const getActiveLink = editor => {
    const [link] = editor.api.nodes({ match: n => n.type === NodeType.LINK });
    return link?.[0] || false;
}

export const unwrapLink = editor => {
    editor.unwrapNodes({
        match: n => n.type === NodeType.LINK,
    });
};

const wrapLink = (editor, url, text) => {
    if (isMissingProtocol(url)) {
        url ="https://" + url;
    }

    const { selection } = editor;
    const isCollapsed = selection && editor.api.isCollapsed(selection);
    const link = {
        type: NodeType.LINK,
        url,
        children: isCollapsed ? [{ text }] : []
    };

    if (isCollapsed) {
        editor.insertNodes(link);
    } else {
        editor.wrapNodes(link, { split: true });
        editor.collapse(editor, { edge: "end" });
    }
};
