import { HEADING_KEYS } from '@udecode/plate-heading';
import {
    BoldPlugin,
    ItalicPlugin,
    UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { BulletedListPlugin, NumberedListPlugin, ListItemPlugin } from '@udecode/plate-list/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { HorizontalRulePlugin } from '@udecode/plate-horizontal-rule/react';
import { ParagraphPlugin } from '@udecode/plate/react';

export const NodeType = {
    PARAGRAPH: ParagraphPlugin.key,
    ORDERED_LIST: NumberedListPlugin.key,
    UNORDERED_LIST: BulletedListPlugin.key,
    LIST_ITEM: ListItemPlugin.key,
    THEMATIC_BREAK: HorizontalRulePlugin.key,
    BLOCK_QUOTE: BlockquotePlugin.key,
    HEADING_ONE: HEADING_KEYS.h1,
    HEADING_TWO: HEADING_KEYS.h2,
    HEADING_THREE: HEADING_KEYS.h3,
    HEADING_FOUR: HEADING_KEYS.h4,
    HEADING_FIVE: HEADING_KEYS.h5,
    HEADING_SIX: HEADING_KEYS.h6,
    LINK: LinkPlugin.key,
}

export const MarkType = {
    EMPHASIS: ItalicPlugin.key,
    STRONG: BoldPlugin.key,
    UNDERLINE: UnderlinePlugin.key,
}