import {someList, unwrapList} from "@udecode/plate-list";
import {NodeType} from "./utils";

export const isBlockActive = (editor, formats = []) => {
    const [match] = editor.api.nodes({
        match: n => {
            return formats.indexOf(n.type) > -1;
        },
    });

    return !!match;
};

export const toggleBlock = (editor, format) => {
    const isList = someList(editor, NodeType.UNORDERED_LIST) || someList(editor, NodeType.ORDERED_LIST);
    editor.tf.withoutNormalizing(() => {
        if (isList) {
            unwrapList(editor);
        }
        editor.tf.toggleBlock(format);
    })
};