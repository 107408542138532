import {
    type CursorData,
    type CursorOverlayState,
    useCursorOverlay,
} from '@udecode/plate-selection/react';
import {toolbarHeight} from "./Toolbar";
import editorStyles from "./styles/SlateEditor.module.scss";
import {extractNumberFromPx} from "../../utils/StringUtilities";

export function Cursor({selectionRects}: CursorOverlayState<CursorData>) {
    return (
        <>
            {selectionRects.map((position, i) => {
                return (
                    <div
                        key={i}
                        className={editorStyles.cursorOverlay}
                        style={{
                            ...position,
                            top: position.top + toolbarHeight - extractNumberFromPx(editorStyles.verticalPadding),
                        }}
                    />
                );
            })}
        </>
    );
}

export function CursorOverlay() {
    const { cursors } = useCursorOverlay();

    return (
        <>
            {cursors.map((cursor) => (
                <Cursor key={cursor.id} {...cursor} />
            ))}
        </>
    );
}